<template>
	<div>
		<CRow class="mb-2">
			<CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
				<h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> 
					{{ this.Edit ? `${this.$t('label.edit')} ${this.$t('label.Entry')}: ${this.ContainerName}` : `${this.$t('label.NewEntry')}`}} 
				</h6>
			</CCol>
		</CRow>
		<div class="mb-2" v-show="showIndex == 1">
			<CRow class="mb-3">
				<CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
					<CButton
						color="edit"
						class="mr-2"
						v-c-tooltip="{
							content: $t('label.return'),
							placement: 'top',
						}"
						size="sm"
						@click="Reset(true)"
					>
						<CIcon name="cil-chevron-left-alt" />
					</CButton>
				</CCol>
			</CRow>
			<CRow class="mt-2 justify-content-center">
				<CCol sm="12" lg="5">
					<CInput
						v-uppercase
						size="sm"
						addLabelClasses="text-right required font-12"
						:label="$t('label.containerCode')"
						:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
						v-model="$v.ContainerCode.$model"
						:is-valid="hasError($v.ContainerCode)"
						:invalid-feedback="errorMessage($v.ContainerCode)"
						:maxlength="11"
					>
						<template #append>
							<CButton
								color="watch"
								square
								size="sm"
								class="rounded"
								v-c-tooltip="{
									content: $t('label.search'), 
									placement: 'top-end'
								}" 
								@click="ContainerSearch()"
								style="padding: 0.15rem 0.4rem;"
							>
								<CIcon name="cil-search"/>
							</CButton>
						</template>
					</CInput>
				</CCol>
				<CCol sm="12" lg="4">
					<CSelect
						v-uppercase
						size="sm"
						addLabelClasses="text-right"
						:label="$t('label.itinerary')"
						:horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
						v-model="$v.Itinerary.$model"
						:is-valid="hasError($v.Itinerary)"
						:invalid-feedback="errorMessage($v.Itinerary)"
						:options="ItineraryOptions"
						:disabled="!NewEntry"
						@change="YardContainerNewGeneralInfo"
					/>
				</CCol>
				<CCol sm="12" lg="5">
					<CSelect
						v-uppercase
						size="sm"
						:addLabelClasses="Itinerary ? 'text-right required' : 'text-right'"
						:label="$t('label.portActivity')"
						:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
						v-model="$v.PortActivity.$model"
						:is-valid="hasError($v.PortActivity)"
						:invalid-feedback="errorMessage($v.PortActivity)"
						:options="PortActivityOptions"
						:disabled="!NewEntry"
					/>
				</CCol>
				<CCol sm="12" lg="4">
					<CSelect
						v-uppercase
						size="sm"
						addLabelClasses="text-right required"
						:label="$t('label.newShippingLine')"
						:horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
						v-model="$v.ShippingLine.$model"
						:is-valid="hasError($v.ShippingLine)"
						:invalid-feedback="errorMessage($v.ShippingLine)"
						:options="ShippingLineOptions"
						:disabled="!NewEntry"
					/>
				</CCol>
				<CCol sm="12" lg="5">
					<CSelect
						v-uppercase
						size="sm"
						addLabelClasses="text-right required"
						:label="$t('label.status')"
						:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
						v-model="$v.Status.$model"
						:is-valid="hasError($v.Status)"
						:invalid-feedback="errorMessage($v.Status)"
						:options="ContainerStatusOptions"
						:disabled="!NewEntry"
					/>
				</CCol>
				<CCol sm="12" lg="4">
					<div class="form-group form-row mb-2">
						<label class="col-form-label-sm col-sm-12 col-lg-4 text-right required">ISO</label>
						<div class="input-group col-sm-12 col-lg-8">
							<v-select
								id="v-select-small"
								:class="$v.Iso.$dirty ? (!$v.Iso.$error ? 'select-client--correct' : 'select-client--error') : ''"
								:placeholder="$t('label.select')"
								:options="IsoOptions"
								:reduce="option => option.value" 
								v-model="Iso"
								style="width: 100%; height: 29px;"
								:disabled="!NewEntry"
							>
								<template #no-options="{}">
									{{$t('label.noResultsFound')}}
								</template>
							</v-select>
							<div class="text-invalid-feedback" v-if="$v.Iso.$error">
								{{ errorMessage($v.Iso) }}
							</div>
						</div>
					</div>
				</CCol>
				<CCol sm="12" lg="5">
					<CInput
						v-uppercase
						size="sm"
						addLabelClasses="text-right required"
						:label="$t('label.size')"
						:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
						v-model="$v.Size.$model"
						:is-valid="hasError($v.Size)"
						:invalid-feedback="errorMessage($v.Size)"
						disabled
					/>
				</CCol>
				<CCol sm="12" lg="4">
					<CInput
						v-uppercase
						size="sm"
						addLabelClasses="text-right required"
						:label="$t('label.type')"
						:horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
						v-model="$v.Type.$model"
						:is-valid="hasError($v.Type)"
						:invalid-feedback="errorMessage($v.Type)"
						disabled
					/>
				</CCol>
				<CCol sm="12" lg="5">
					<CSelect
						v-uppercase
						size="sm"
						addLabelClasses="text-right"
						:label="$t('label.client')"
						:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
						v-model="$v.Client.$model"
						:is-valid="hasError($v.Client)"
						:invalid-feedback="errorMessage($v.Client)"
						:options="ClientOptions"
						:disabled="!NewEntry"
					>
						<template #append>
							<CButton
								color="add"
								size="sm"
								class="rounded"
								v-c-tooltip="{
										content:$t('label.nuevo')+' '+$t('label.client'),
										placement: 'top-end'
								}"
								@click="ClientModal=true"
								style="padding: 0.15rem 0.4rem;"
							>
								<CIcon name="cil-playlist-add"/>
							</CButton>
						</template>
					</CSelect>
				</CCol>
				<CCol sm="12" lg="4">
					<CSelect
						v-uppercase
						size="sm"
						addLabelClasses="text-right"
						label="IMDG"
						:horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
						v-model="$v.Imdg.$model"
						:is-valid="hasError($v.Imdg)"
						:invalid-feedback="errorMessage($v.Imdg)"
						:options="ImdgOptions"
						:disabled="!NewEntry"
					/>
				</CCol>
				<CCol sm="12" lg="5">
					<div class="form-group form-row mb-2">
						<label class="col-form-label-sm col-sm-12 col-lg-5 text-right">SHIPPER OWNED</label>
						<div class="d-flex justify-content-end col-sm-12 col-lg-auto ">
							<CSwitch
								color="watch"
								variant="3d"
								type="checkbox"
								:checked.sync="FgShipperOwned"
								:disabled="!NewEntry"
							/>
						</div>
					</div>
				</CCol>
				<CCol sm="12" lg="4">
					<CInput
						v-uppercase
						size="sm"
						:addLabelClasses="FgShipperOwned ? 'text-right required' : 'text-right'"
						label="SHIPPER OWNED"
						:horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
						v-model="$v.ShipperOwned.$model"
						:is-valid="hasError($v.ShipperOwned)"
						:invalid-feedback="errorMessage($v.ShipperOwned)"
						:disabled="!NewEntry||!FgShipperOwned"
					/>
				</CCol>
				<CCol sm="12" lg="5">
					<CInput
						v-uppercase
						size="sm"
						addLabelClasses="text-right required"
						:label="$t('label.Procedence')"
						:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
						v-model="$v.Procedence.$model"
						:is-valid="hasError($v.Procedence)"
						:invalid-feedback="errorMessage($v.Procedence)"
						:disabled="!NewEntry"
					/>
				</CCol>
				<CCol sm="12" lg="4">
					<CInput
						v-uppercase
						size="sm"
						addLabelClasses="text-right"
						label="BOOKING"
						:horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
						v-model="$v.Booking.$model"
						:is-valid="hasError($v.Booking)"
						:invalid-feedback="errorMessage($v.Booking)"
						:disabled="!NewEntry"
					/>
				</CCol>
				<CCol sm="12" lg="5" v-if="this.Edit">
					<CSelect
						size="sm"
						:options="statusOptions"
						:value.sync="StatusRegister"
						:label="$t('label.status')"
						:horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
						addLabelClasses="required text-right"
						:is-valid="statusSelectColor"
					/>
				</CCol>
				<CCol sm="12" lg="4"  v-if="this.Edit"></CCol>

				<CCol sm="12" class="d-flex justify-content-end mb-2">
					<CButton
						color="watch"
						size="sm"
						class="mr-1"
						@click="ContinueNewEntry()"
					>
						<CIcon name="continue" />&nbsp; {{$t('label.Continue')}}
					</CButton>
				</CCol>
			</CRow>
		</div>
		<div v-show="showIndex == 2">
			<NewEntryStepTwo
				:NewEntry="showIndex == 2"
				:SearchContainer="SearchContainer"
				:NewEntryForm="NewEntryForm"
				:NewGeneralInfo="NewGeneralInfo"
				@Close="showIndex = 1"
				@SubmitNewEntry="NewRegister"
			/>
		</div>
		<div v-show="showIndex == 3">
			<PendingRegister
				:Active="showIndex== 3"
				:ContainerItem="ContainerItem"
				@Close="showIndex = 1"
				@Update-list="Update"
				@Return="Return"
			/>
		</div>
		<ClientModal 
			:modal="ClientModal"
			@cerrarModal="ClientModal=false" 
			@UpdateClient="YardManagementGeneralClient(false)" 
		/>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import General from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { StepOne } from '@/_validations/yard-management/movements/newEntryValidations';
import ClientModal from '../../container/general-info/client-modal';
import NewEntryStepTwo from "./new-entry-step-two";
import PendingRegister from "./pending-register";
import ModalMixin from '@/_mixins/modal';

//data
function data() {
	return {
		Edit: false,
		SearchContainer: false,
		NewEntry: false,
		ClientModal: false,
		FgShipperOwned: false,
		showIndex: 1,
		ContainerName: '',
		CargoId: '',
		ContainerId: '',
		ContainerCode: '',
		PortActivity: '',
		PortActivityList: [],
		ShippingLine: '',
		ShippingLineList: [],
		Client: '',
		ClientList: [],
		Procedence: '',
		Itinerary: '',
		ItineraryList: [],
		Booking: '',
		StatusRegister: 1,
		Status: '',
		ContainerStatus: [],
		Size: '',
		Type: '',
		Iso: '',
		IsoList: [],
		Imdg: '',
		ImdgList: [],
		ShipperOwned: '',
		NewGeneralInfo: [],
		NewEntryForm: {},
		ContainerItem: {},
	};
}

//methods
async function ContainerSearch() {
	try {
		await this.$v.ContainerCode.$touch();
		if (!this.$v.ContainerCode.minLength||!this.$v.ContainerCode.required) {
			throw 'error'
		}
		this.$store.state.yardManagement.loading = true;
		let CargoJson = {
			ContainerCode: this.ContainerCode,
			CompanyBranchId: this.branch.CompanyBranchId,
		}
		this.$http.post('YardContainerSearch', CargoJson, { root: 'CargoJson' })
			.then(async response => {
				let List = response.data.data ?? [];
				if (List.length != 0 && Object.entries(List[0]).length > 1) {
					if (!this.Edit || this.ContainerCode == this.ContainerName) {
						let CargoMasterJson = List[0]?.CargoMasterJson&&List[0]?.CargoMasterJson.length!=0 ?
							List[0]?.CargoMasterJson[0] : {};
						this.FgShipperOwned = List[0].FgActShipperOwn ?? false;
						this.CargoId = List[0].CargoId ?? '',
						this.ContainerId = List[0].ContainerId ?? '',
						this.PortActivity = CargoMasterJson.PortActivictyId ? (CargoMasterJson.PortActivictyId).toUpperCase() : '';
						this.Client = CargoMasterJson.ClientTpId ?? '';
						this.Procedence = CargoMasterJson.Procedence ?? '';
						this.Itinerary = CargoMasterJson.ItineraryId ?? '';
						this.Booking = CargoMasterJson.BookingNro ?? '';
						this.Status = CargoMasterJson.TpCargoStatusId ?? '';
						this.Imdg = CargoMasterJson.ImdgId ?? '';
						this.ShipperOwned = List[0].ShipperOwn ?? '';

						await this.NewEntryServices();
						this.ShippingLine = CargoMasterJson.ShippingLineId ?? '';
						this.Iso = List[0].MetadataId ?? '';
						this.NewEntry = true;
						this.SearchContainer = true;
						this.$v.$touch();
						this.NewEntryForm = {
							TpCargoStatusId: CargoMasterJson.TpCargoStatusId ?? '',
							TpCargoClassId: List[0].TpCargoClassId ?? '',
							LoadPortId: CargoMasterJson.LoadPortId ?? '',
							DischargePortId: CargoMasterJson.DischargePortId ?? '',
							DeliveryPortId: CargoMasterJson.DeliveryPortId ?? '',
							OriginalPortLoadingId: CargoMasterJson.OriginalPortLoadingId ?? '',
							DescriptionType: CargoMasterJson.DescriptionType ?? '',
							Weigth: CargoMasterJson.Weigth ?? 0,
							UnitMeasureWeigth: CargoMasterJson.UnitMeasureWeigth ?? '',
							Vgm: CargoMasterJson.Vgm ?? 0,
							UnitMeasureVgm: CargoMasterJson.UnitMeasureVgm ?? '',
							TpCargoConditionId: CargoMasterJson.TpCargoConditionId ?? '',
							DryReefer: CargoMasterJson.DryReefer ? true : false,
							UnitMeasureRf: CargoMasterJson.UnitMeasureRf ?? '',
							Plugged: CargoMasterJson.DryReefer ? true : false,
							TpPowerPlugId: CargoMasterJson.TpPowerPlugId ?? '',
							ReeferSetting: CargoMasterJson.ReeferSetting ?? '',
							MinimumRangeRf: CargoMasterJson.MinimumRangeRf ?? '',
							MaximumRangeRf: CargoMasterJson.MaximumRangeRf ?? '',
							UnitMeasureDim: CargoMasterJson.UnitMeasureDim ?? '',
							DimFront: CargoMasterJson.DimFront ?? 0,
							DimBack: CargoMasterJson.DimBack ?? 0,
							DimLeft: CargoMasterJson.DimLeft ?? 0,
							DimRight: CargoMasterJson.DimRight ?? 0,
							DimTop: CargoMasterJson.DimTop ?? 0,
							TranshipmentPortId: CargoMasterJson.TranshipmentPortId ??'',
							LastVisitJson: List[0].LastVisitJson ?? [],
							LastYardJson: List[0].LastYardJson ?? [],
						};
					} else {
						this.Reset(false);
						this.$v.ContainerCode.$touch();
						this.$notify({
							group: 'container',
							title: '¡Error!',
							text: 'ERROR',
							type: "error"
						});
					}
				} else {
					this.Reset(false);
					this.$v.ContainerCode.$touch();
					await this.NewEntryServices();
				}
			})
			.catch(err => {
				this.$notify({
					group: 'container',
					title: '¡Error!',
					text: err,
					type: "error"
				});
			})
			.finally(() => {
				this.$store.state.yardManagement.loading = false;
			});
	} catch (error) {
		this.Reset(false);
		this.$v.ContainerCode.$touch();
	}
}

async function NewEntryServices() {
	let Requests = [
		this.$http.ejecutar("GET", "YardServiceItinerary-by-CompanyBranchId", { CompanyBranchId: this.branch.CompanyBranchId }),
		this.$http.ejecutar("GET", "TpCargoDetail-by-codiso?CodIso"),
    ];

  	await Promise.all(Requests)
		.then(async responses => {
			this.ItineraryList =  responses[0].data.data ?? [];
			this.IsoList = responses[1].data.data ?? [];
		})
		.catch(err => {
			this.$notify({
				group: 'container',
				title: '¡Error!',
				text: err,
				type: "error"
			});
		})
	await this.YardManagementGeneralClient(true);
	await this.YardContainerNewGeneralInfo();
}

async function YardContainerNewGeneralInfo() {
	this.ShippingLine = '';
	this.NewEntryForm.LoadPortId = '';
	this.NewEntryForm.DischargePortId = '';
	this.$store.state.yardManagement.loading = true;
	await this.$http.get('YardContainerNewGeneralInfo', {ItineraryId: this.Itinerary})
		.then(async response => {
			this.NewGeneralInfo = response.data.data ?? [];
			if (this.NewGeneralInfo.length != 0) {
				this.PortActivityList = this.NewGeneralInfo[0]?.TpPortActivityJson ?? [];
				this.ShippingLineList = this.NewGeneralInfo[0]?.ServiceLineMasterJson ?? [];
				this.ContainerStatus = this.NewGeneralInfo[0]?.TpCargoStatusJson ?? [];
				this.ImdgList = this.NewGeneralInfo[0]?.ImdgJson ?? [];
			}else{
				this.PortActivityList = [];
				this.ShippingLineList = [];
				this.ContainerStatus = [];
			}
			this.NewEntry = true;
		})
		.catch(async err => {
			this.PortActivityList = [];
			this.ShippingLineList = [];
			this.ContainerStatus = [];
			this.$notify({
				group: 'container',
				title: '¡Error!',
				text: err,
				type: "error"
			});
		})
		.finally(() => {
			this.$store.state.yardManagement.loading = false;
		});
}

async function YardManagementGeneralClient(loadingEnd) {
	this.$store.state.yardManagement.loading = true;
	await this.$http.ejecutar("GET", "YardManagementGeneralClient-list")
		.then(response => {
			this.ClientList = response.data.data ?? [];
		})
		.catch(async err => {
			this.ClientList = [];
			this.$notify({
				group: 'container',
				title: '¡Error!',
				text: err,
				type: "error"
			});
		})
		.finally(() => {
			this.$store.state.yardManagement.loading = loadingEnd;
		});
}

async function ContinueNewEntry() {
	try {
		await this.$v.$touch();
		if (this.$v.PortActivity.$error||this.$v.ShippingLine.$error||this.$v.Client.$error||
			this.$v.Procedence.$error||this.$v.Itinerary.$error||this.$v.Booking.$error||this.$v.Status.$error||
			this.$v.Size.$error||this.$v.Type.$error||this.$v.Iso.$error||this.$v.Imdg.$error||this.$v.Iso.$error||
			this.$v.Imdg.$error||this.$v.ShipperOwned.$error||!this.$v.ContainerCode.minLength||!this.$v.ContainerCode.required) {
			throw this.$t('label.errorsPleaseCheck');
		}
		let IsoData = this.IsoList.find(item => item.MetadataId == this.Iso);
		this.NewEntryForm = { 
			...this.NewEntryForm,
			TpCargoConditionId: this.NewEntryForm?.TpCargoStatusId == this.Status ? this.NewEntryForm?.TpCargoConditionId : '',
			TpCargoStatusId: this.Status,
			CargoId: this.CargoId,
			ContainerId: this.ContainerId,
			ContainerCode: this.ContainerCode,
			PortActivity: this.PortActivity,
			ShippingLine: this.ShippingLine,
			Client: this.Client,
			Procedence: this.Procedence,
			Itinerary: this.Itinerary,
			Booking: this.Booking,
			Status: this.Status,
			ContainerStatus: this.ContainerStatus,
			Size: IsoData.SizeId,
			Type: this.Type,
			Iso: this.Iso,
			IsoList: IsoData,
			Imdg: this.Imdg,
			FgShipperOwned: this.FgShipperOwned,
			ShipperOwned: this.ShipperOwned,
			StatusRegister: this.StatusRegister,
		}
		this.showIndex = 2;
	} catch (error) {
		this.$notify({
			group: 'container',
			title: '¡Error!',
			text: error,
			type: "error"
		});
	}
}

async function NewRegister(Container) {
	if (Container.FgActCargoMaster) {
		this.ContainerItem = Container;
		await this.$emit('Update-list', true);
		this.showIndex = 3;
	}else{
		this.Reset(true);
		await this.$emit('Update-list', false);
	}
}

async function Update(loading) {
	await this.Reset(true);
	await this.$emit('Update-list', loading);
}

function statusSelectColor() {
  return this.StatusRegister === 1;
}
function Return(event) {
	this.ContainerName = event.ContainerCode;
	this.Edit=true; 
	this.ContainerSearch();
}

function Reset(All) {
	this.StatusRegister = 1;
	this.SearchContainer = false;
	this.NewEntry = false;
	this.ClientModal = false;
	this.FgShipperOwned = false;
	this.showIndex = 1;
	this.CargoId = '',
	this.ContainerId = '',
	this.ContainerCode = All ? '': this.ContainerCode;
	this.PortActivity = '';
	this.PortActivityList = [];
	this.ShippingLine = '';
	this.ShippingLineList = [];
	this.Client = '';
	this.ClientList = [];
	this.Procedence = '';
	this.Itinerary = '';
	this.ItineraryList = [];
	this.Booking = '';
	this.Status = '';
	this.ContainerStatus = [];
	this.Size = '';
	this.Type = '';
	this.Iso = '';
	this.IsoList = [];
	this.Imdg = '';
	this.ImdgList = [];
	this.ShipperOwned = '';
	this.NewGeneralInfo = [];
	this.NewEntryForm = {};
	this.ContainerItem = {};
	this.$v.$reset();
	if (All) {
		this.Edit = false;
		this.ContainerName = '';
		this.$emit('Close');
	}
}

//computed
function ShippingLineOptions() {
	if(this.ShippingLineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ShippingLineList.map(function(e){
      chart.push({
        value: e.ShippingLineId,
        label: `${e.ShippingLineName} (${e.ShippingLineCode})`,
      })
    })
    return chart;
  }
}

function ClientOptions() {
	if(this.ClientList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ClientList.map(function(e){
      chart.push({
        value: e.ClientTpId,
        label: e.ClientName,
      })
    })
    return chart;
  }
}

function PortActivityOptions() {
	if(this.PortActivityList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.PortActivityList.map(function(e){
      chart.push({
        value: e.PortActivityId,
        label: e[`PortActivityName${_lang}`] ?? '',
      })
    })
    return chart;
  }
}

function ItineraryOptions() {
	if(this.ItineraryList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ItineraryList.map(function(e){
      chart.push({
        value: e.ItineraryId,
        label: `${e.VesselName} - ${e.Voyage} - ${e.LabelArrival}: ${DateFormater.formatDateTimeWithSlash(e.Arrival)}`,
      })
    })
    return chart;
  }
}

function ContainerStatusOptions() {
  if(this.ContainerStatus.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ContainerStatus.map(function(e){
      chart.push({
        value: e.TpCargoStatusId,
        label: e.TpCargoStatusName,
      })
    })
    return chart;
  }
}

function ImdgOptions() {
	if(this.ImdgList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.ImdgList.map(function(e){
      chart.push({
        value: e.ImdgId,
        label: `${e[`ImdgName${_lang}`]} (${e.ImdgCod})`,
      })
    })
    return chart;
  }
}

function IsoOptions() {
	if(this.IsoList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.IsoList.map(function(e){
      chart.push({
        value: e.MetadataId,
        label: e.MetadataDesc,
      })
    })
    return chart;
  }
}
		
export default{
	name: 'new-entry',
	data,
	props: {Active: Boolean, isEdit: Boolean, ContainerInfo: Object},
	mixins: [General, ModalMixin],
	components: {
		ClientModal,
		NewEntryStepTwo,
		PendingRegister,
	},
	validations(){
		return StepOne(this.FgShipperOwned, this.Itinerary);
	},
	directives: UpperCase,
	methods: {
		ContainerSearch,
		NewEntryServices,
		YardContainerNewGeneralInfo,
		YardManagementGeneralClient,
		ContinueNewEntry,
		NewRegister,
		Return,
		Reset,
		Update,
		statusSelectColor,
	},
	computed: {
		ShippingLineOptions,
		ClientOptions,
		PortActivityOptions,
		ItineraryOptions,
		ContainerStatusOptions,
		ImdgOptions,
		IsoOptions,
		...mapState({
			YardId: state => state.yardManagement.yardData.YardId,
			dropItemMovementList: state => state.yardManagement.dropItemMovementList,
			branch: state => state.auth.branch,
		})
	},
	watch:{
		Active: function (NewVal) {
			if (NewVal) {
				this.Edit = this.isEdit;
				if (this.isEdit) {
					this.ContainerCode = this.ContainerInfo.ContainerCode;
					this.ContainerName = this.ContainerInfo.ContainerCode;
					this.StatusRegister = this.ContainerInfo.Status;
					this.ContainerSearch();
				};
			}
		},rCode: function (NewVal, OldVal) {
			if (NewVal!=OldVal) {
				this.Reset(false);
			}
		},
		Iso: function (NewVal) {
			this.Size = '';
			this.Type = '';
			if (NewVal) {
				let IsoData = this.IsoList.find((item) => { return  NewVal === item.MetadataId });
				this.Type = IsoData?.TpCargoName ?? '';
        		this.Size = IsoData?.Size ?? '';
			}
		}
	}
}
</script>
<style lang="scss">
.font-12{
	font-size: 12px !important;
}
</style>